import "../App.css";
import "../styles/confirmation.css";
import React, { useEffect } from "react";
import iconImage from "../assets/img/wooblly-logo.svg";
import { useTranslation } from "react-i18next";
import "../i18n";

const AppleConfirmationPage = () => {
  const { t } = useTranslation("global");

  useEffect(() => {
    // Parse the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const status = urlParams.get("status");

    // Construct the app deep link with the extracted parameters
    let appDeepLink = `wooblly://confirmation`;
    if (code && status) {
      appDeepLink += `?code=${code}&status=${status}`;
    }

    // Redirect to the app
    window.location.href = appDeepLink;
  }, [t]);

  return (
    <div className="confirmation-container">
      <div className="envelope-content">
        <img src={iconImage} alt="Icon" className="icon-image" />
      </div>
    </div>
  );
};

export default AppleConfirmationPage;
