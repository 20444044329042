import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import WelcomePage from "./pages/welcome";
import ConfirmationPage from "./pages/confirmation";
import SetPasswordPage from "./pages/password";
import TermsAndConditionsPage from "./pages/terms_and_conditions";
import PolicyNotesPage from "./pages/policy_notes";
import AppleConfirmationPage from "./pages/apple_confirmation";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/reset-password" element={<SetPasswordPage />} />
          <Route path="/confirm-email" element={<ConfirmationPage />} />
          <Route path="/policy-notes" element={<PolicyNotesPage />} />
          <Route
            path="/apple-confirmation"
            element={<AppleConfirmationPage />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
