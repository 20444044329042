import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import iconImage from "../assets/img/wooblly-logo.svg";
import { Password } from "primereact/password";
import "../App.css";
import "../styles/input.css";
import "../i18n";
import SmileyFace from "../assets/img/smiley-face.svg";
import AngryFace from "../assets/img/angry-face.svg";
import UpsetFace from "../assets/img/upset-face.svg";
import HappyFace from "../assets/img/happy-face.svg";

const SetPasswordPage = () => {
  const { t } = useTranslation("global");
  const baseUrl = process.env.REACT_APP_WOOBLLY_BASE_URL;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    number: false,
    capitalLetter: false,
    smallLetter: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert(t("passwords_do_not_match"));
      return;
    }
    var token = getQueryParam("confirmation-token");
    if (token) {
      const requestBody = {
        password: password,
        confirmationToken: token,
      };
      resetPassword(requestBody);
    }
  };

  const resetPassword = async (requestBody) => {
    try {
      const apiUrl = `${baseUrl}/api/v1/authentication/reset-password`;
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || t("something_went_wrong"));
        return;
      }
      const data = await response.json();
      alert(t("password_reset_successful"));
    } catch (error) {
      alert(t("something_went_wrong"));
    }
  };

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const passwordRequirementsList = [
    {
      label: `8 ${t("characters")}`,
      key: "length",
    },
    {
      label: `1 ${t("capital_letter")}`,
      key: "capitalLetter",
    },
    {
      label: `1 ${t("small_letter")}`,
      key: "smallLetter",
    },
    {
      label: `1 ${t("number")}`,
      key: "number",
    },
  ];

  const passwordStrenghtIcon = useMemo(() => {
    const requirements = Object.values(passwordRequirements);
    const requirementsChecked = requirements.filter(Boolean).length;

    if (requirementsChecked === 4) {
      return HappyFace;
    }
    if (requirementsChecked >= 3) {
      return UpsetFace;
    }
    if (requirementsChecked >= 1) {
      return AngryFace;
    }

    return SmileyFace;
  }, [passwordRequirements]);

  const updatePasswordRequirement = useCallback((key, value) => {
    setPasswordRequirements((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const checkPasswordLength = useCallback(
    (password) => {
      updatePasswordRequirement("length", password.length >= 8);
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasNumber = useCallback(
    (password) => {
      updatePasswordRequirement("number", /\d/.test(password));
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasCapitalLetter = useCallback(
    (password) => {
      updatePasswordRequirement("capitalLetter", /[A-Z]/.test(password));
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasSmallLetter = useCallback(
    (password) => {
      updatePasswordRequirement("smallLetter", /[a-z]/.test(password));
    },
    [updatePasswordRequirement]
  );

  const handleCheckPasswordStrenght = useCallback(() => {
    checkPasswordLength(password);
    checkPasswordHasNumber(password);
    checkPasswordHasCapitalLetter(password);
    checkPasswordHasSmallLetter(password);
  }, [
    checkPasswordLength,
    checkPasswordHasNumber,
    checkPasswordHasCapitalLetter,
    checkPasswordHasSmallLetter,
    password,
  ]);

  const handleChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  useEffect(() => {
    handleCheckPasswordStrenght();
  }, [handleCheckPasswordStrenght]);

  return (
    <div className="password-reset-container">
      <div className="logo">
        <img src={iconImage} alt="Woobbly Logo" />
      </div>
      <h1>{t("set_new_password")}</h1>
      <p>{t("reset_passoword")}</p>
      <form onSubmit={handleSubmit}>
        <div className="input-group grid m-0 mb-4">
          <div className="col-12 flex justify-content-between align-items-center p-0 mb-2">
            <label htmlFor="password">{t("password")}</label>
            <img
              src={passwordStrenghtIcon}
              alt="Lock Icon"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div className="col-12 relative flex p-0">
            <i className="pi pi-lock lock-icon" />
            <Password
              id="password"
              onChange={handleChangePassword}
              className="c-password-input"
              placeholder={t("password_placeholder")}
              toggleMask
              feedback={false}
            />
          </div>
        </div>
        <div className="input-group grid m-0 mb-4">
          <div className="col-12 flex justify-content-between p-0 mb-2">
            <label htmlFor="confirm-password">{t("confirm_password")}</label>
          </div>
          <div className="col-12 relative flex p-0">
            <i className="pi pi-lock lock-icon" />
            <Password
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="c-password-input"
              placeholder={t("confirm_password_placeholder")}
              toggleMask
              feedback={false}
            />
          </div>
        </div>
        <div className="password-requirements mb-4">
          {t("password_constraints_info")}:
          <ul>
            {passwordRequirementsList.map((requirement, index) => {
              return (
                <li key={index}>
                  {!!passwordRequirements[requirement.key] && (
                    <i
                      className="pi pi-check mr-1"
                      style={{
                        color: "green",
                        fontSize: "0.8rem",
                        marginLeft: "-20px",
                      }}
                    />
                  )}

                  {requirement.label}
                </li>
              );
            })}
          </ul>
        </div>
        <button type="submit" className="set-password-btn">
          <span>{t("set_password")}</span>
        </button>
      </form>
    </div>
  );
};

export default SetPasswordPage;
